<template>
  <div class="modal">
    <div class="close" @click="close">&times;</div>
    <div class="body">
      <slot name='body' />
    </div>
  </div>  
</template>

<script>
    export default {
        name: 'Modal',
        methods: {
            close() {
                this.$emit('close')
            }
        }
    }
</script>


<style scoped>
 .modal {
  background-color: rgba(0,0,0,0.5);
  /* position: absolute; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 50px;
}

.modal .body {
  background-color:  rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;

}

.modal .body img {
    /* margin-top: 20%; */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -225px;
}

.modal .close {
  right: 30px;
  position: relative;
  font-size: 30px;
  text-align: right;
  top: 10px;
  cursor: pointer;
}

.show {
    display: block;
}
</style>
