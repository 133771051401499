import Vue from 'vue';
import App from './App.vue';
import 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueWow from 'vue-wow';
import "./assets/js/kidz";
import VueRouter from 'vue-router';
//import Vuex from "vuex";
import Accueil from './components/Accueil';

import Moment from 'moment';


Vue.config.productionTip = false
//Vue.use(jQuery);
Vue.use(VueWow);
Vue.use(VueRouter);
//Vue.use(Vuex);
Vue.use(Moment);

const router = new VueRouter({	//on construit notre router
	//contiendra les routes vers les différents components (écrites dans Routes.js)
	routes: [
		{ path: '/', component: Accueil }
		//{ path: '*', redirect: '/' }
	],
	mode: 'history',	//donne le comportement classique d’un site
	base: __dirname
});

// const store = new Vuex.Store(
// 	{
// 		state: {
// 			authenticated: false
// 		},
// 		mutations: {
// 			setAuthentication(state, status) {
// 				state.authenticated = status;
// 			}
// 		}
// 	}
// );


new Vue({
	render: h => h(App),
	router: router
	//store: store
}).$mount('#app')
